/* eslint-disable react/no-unknown-property */
/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { urlFor } from '../lib/sanity';
import imagePanelStyles from '../styles/LeadingImagePanelHome.module.scss';

function LeadingImagePanel({ HeaderImage, width = '1920', height = '680' }) {
  return (
    <div className={imagePanelStyles.imageHolder}>
      {/* <img src={urlFor(HeaderImage.headerimage).width(`${width}`).height(`${height}`).url()} alt={HeaderImage?.headerimage?.alt} className={imagePanelStyles.leadingImage} width={`${width}`} height={`${height}`} style={{ minHeight: `${minHeight}px` }} /> */}

      <picture>
        <source media="(min-width:768px)" srcSet={urlFor(HeaderImage.headerimage).width(`${width}`).height(`${height}`).url()} />
        <img src={urlFor(HeaderImage.headerimage).auto('format').width(450).height(450).url()} alt={HeaderImage?.headerimage?.alt} className={imagePanelStyles.leadingImage} width={`${width}`} />
      </picture>

      <div className={imagePanelStyles.container}>
        <div className={imagePanelStyles.contentPanel} style={{ backgroundImage: 'url(/images/static/content-panel-back-light-blue.png)' }}>
          <h2>An award winning childcare provider with two Ofsted Outstanding nurseries in Greenwich South East London</h2>
          <p>Are you looking for quality nursery care in Greenwich? Start your More2 journey today by booking a tour of the nursery. </p>

          <Link href={'/admissions/book-a-tour'}>
            <button className={imagePanelStyles.telTo}>Book a Tour</button>
          </Link>
          <Link href={'/admissions/register-your-child'}>
            <button className={imagePanelStyles.telTo}>Register your child</button>
          </Link>
        </div>
      </div>
    </div>
  );
}



export default LeadingImagePanel;
